import { Dimensions } from 'react-native';
import { createSlice } from '@reduxjs/toolkit';

type viewType = {
  width: number;
};

const windowWidth = Dimensions.get('window').width;

const initialState: viewType = {
  width: windowWidth > 600 ? 600 : windowWidth,
};

// ブラウザバック or 画面を戻った場合のstateを管理
export const viewSlice = createSlice({
  name: 'viewSlice',
  initialState: initialState,
  reducers: {
    resetWidth: (state) => {
      const w = Dimensions.get('window').width;
      state.width = w > 600 ? 600 : w;
    },
  },
});

export const { resetWidth } = viewSlice.actions;

import React from 'react';
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom';

type PropsType = {
  to: string;
  children: React.ReactNode;
} & LinkProps;

const userAgent = window.navigator.userAgent.toLowerCase();

const Link = (props: PropsType) => {
  // Firefoxのみブラウザバック時にReactRouterDomのリンクが正しく挙動しない
  if (userAgent.indexOf('firefox') !== -1) {
    return <a href={props.to}>{props.children}</a>;
  } else {
    return <ReactRouterLink to={props.to}>{props.children}</ReactRouterLink>;
  }
};

export default Link;

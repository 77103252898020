import React from 'react';
import { StatusBar, Box, Center } from 'native-base';

type PropsType = {
  children: React.ReactNode;
};

const HeaderLayout = (props: PropsType) => {
  return (
    <>
      <StatusBar backgroundColor="#ffffff" barStyle="dark-content" />
      <Box safeAreaTop backgroundColor="#ffffff" />
      <Box
        backgroundColor="#ffffff"
        borderBottomWidth="1px"
        borderColor="#cccccc"
        width="100%"
        position="fixed"
        top="0"
        zIndex="9999">
        <Center>
          <Box width="100%" maxWidth="960px" height="40px" padding={1}>
            {props.children}
          </Box>
        </Center>
      </Box>
    </>
  );
};

export default HeaderLayout;

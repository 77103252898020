import React from 'react';
import { Provider } from 'react-redux';
import { NativeBaseProvider } from 'native-base';

import Route from '@app/routes';
import { store } from '@app/store';
import { theme } from '@config';

const App = () => {
  return (
    <Provider store={store}>
      <NativeBaseProvider theme={theme}>
        <Route />
      </NativeBaseProvider>
    </Provider>
  );
};

export default App;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Location } from 'react-router-dom';
import { Box, IBoxProps } from 'native-base';

import { usePrevious } from '@app/hooks/usePrevious';
import { setBack } from '@app/slices/backSlice';
import { RootState } from '@app/store';

type PropsType = {
  children: React.ReactNode;
} & IBoxProps;

const ScrollView = (props: PropsType) => {
  const location: Location = useLocation();
  const prevLocation: any = usePrevious(location);

  const back = useSelector((state: RootState) => state.backSlice.back);
  const dispatch = useDispatch();

  // ブラウザバックが行われた場合はbackをtrueに
  const browserBackBlockFunc = () => {
    dispatch(setBack(true));
  };
  React.useEffect(() => {
    // ブラウザバックを検知する
    window.addEventListener('popstate', browserBackBlockFunc);
    //クリーンアップ
    return () => {
      window.removeEventListener('popstate', browserBackBlockFunc);
    };
  });

  React.useEffect(() => {
    // 画面遷移があった場合はbackをfalseに
    if (back && location.pathname !== prevLocation?.pathname) {
      dispatch(setBack(false));
    }
  }, [back, dispatch, location, prevLocation]);

  return (
    <Box {...props} marginTop="40px">
      {props.children}
    </Box>
  );
};

export default ScrollView;

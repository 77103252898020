import { combineReducers } from '@reduxjs/toolkit';

import { backSlice } from '@app/slices/backSlice';
import { viewSlice } from '@app/slices/viewSlice';

import { scrollSlice } from '@components/ScrollView/scrollSlice';

import { homeSlice } from '@pages/Home/homeSlice';
import { homeNewPostRestaurantsApi } from '@pages/Home/homeNewPostRestaurantsApi';
import { restaurantApi } from '@pages/Restaurant/restaurantApi';
import { restaurantsApi } from '@pages/Restaurant/restaurantsApi';
import { restaurantsSlice } from '@pages/Restaurant/restaurantsSlice';

export const rootReducer = combineReducers({
  // @app
  [backSlice.name]: backSlice.reducer,
  [viewSlice.name]: viewSlice.reducer,
  // @ui
  [scrollSlice.name]: scrollSlice.reducer,
  // @pages
  [homeNewPostRestaurantsApi.reducerPath]: homeNewPostRestaurantsApi.reducer,
  [homeSlice.name]: homeSlice.reducer,
  [restaurantsApi.reducerPath]: restaurantsApi.reducer,
  [restaurantApi.reducerPath]: restaurantApi.reducer,
  [restaurantsSlice.name]: restaurantsSlice.reducer,
});

export const apiMiddleWares = [
  homeNewPostRestaurantsApi.middleware,
  restaurantsApi.middleware,
  restaurantApi.middleware,
];

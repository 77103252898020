import React from 'react';
import { Center, Text } from 'native-base';

import HeaderLayout from '@components/Header/HeaderLayout';
import Link from '@components/Link';

const CommonHeader = () => {
  return (
    <HeaderLayout>
      <Center height="32px">
        <Link to="/">
          <Text color="#000000" fontSize="xl" fontWeight="bold">
            DishNote
          </Text>
        </Link>
      </Center>
    </HeaderLayout>
  );
};

export default CommonHeader;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StateType = {
  restaurants: any[];
  page: number;
  lastPage: number;
};

const initialState: StateType = {
  restaurants: [],
  page: 1,
  lastPage: 1,
};

export const homeSlice = createSlice({
  name: 'homeSlice',
  initialState: initialState,
  reducers: {
    setHomeState: (state, action: PayloadAction<StateType>) => {
      state.restaurants = action.payload.restaurants;
      state.page = action.payload.page;
      state.lastPage = action.payload.lastPage;
    },
  },
});

export const { setHomeState } = homeSlice.actions;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from '@config';

export const restaurantApi = createApi({
  reducerPath: 'restaurantApi',
  baseQuery: fetchBaseQuery({ baseUrl: config.API_BASE_URL }),
  endpoints: (builder) => ({
    getRestaurant: builder.query({
      query: (id: number) => `/restaurants/${id}`,
    }),
  }),
});

export const { useGetRestaurantQuery } = restaurantApi;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ScrollType = {
  y: number;
};

const initialState: ScrollType = {
  y: 0,
};

export const scrollSlice = createSlice({
  name: 'scroll',
  initialState: initialState,
  reducers: {
    setY: (state, action: PayloadAction<number>) => {
      state.y = action.payload;
    },
  },
});

export const { setY } = scrollSlice.actions;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type backType = {
  back: boolean;
};

const initialState: backType = {
  back: false,
};

// ブラウザバック or 画面を戻った場合のstateを管理
export const backSlice = createSlice({
  name: 'backSlice',
  initialState: initialState,
  reducers: {
    setBack: (state, action: PayloadAction<boolean>) => {
      state.back = action.payload;
    },
  },
});

export const { setBack } = backSlice.actions;

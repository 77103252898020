import React, { Suspense } from 'react';
import { Text } from 'native-base';
import { Routes, Route } from './reactRouter';

import CommonHeader from '@components/Header';
import RestaurantHeader from '@components/Header/RestaurantHeader';

const HeaderRoutes = () => {
  return (
    <Suspense fallback={<Text>Loading...</Text>}>
      <Routes>
        <Route path="/restaurants/:area1/:area2/:area3/:id" element={<RestaurantHeader />} />
        <Route path="*" element={<CommonHeader />} />
      </Routes>
    </Suspense>
  );
};

export default HeaderRoutes;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, Location } from 'react-router-dom';
import { Box, HStack, Text, IconButton, ChevronLeftIcon } from 'native-base';

import { setBack } from '@app/slices/backSlice';

import HeaderLayout from '@components/Header/HeaderLayout';
import Link from '@components/Link';

const RestaurantHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location: Location = useLocation();

  return (
    <HeaderLayout>
      <HStack space={2} height="32px" justifyContent="space-between" alignItems="center">
        {location.pathname === '/' ? (
          <Box width="32px" />
        ) : (
          <Box width="32px">
            <IconButton
              icon={<ChevronLeftIcon />}
              size="sm"
              borderRadius="full"
              padding={0}
              _hover={{
                _icon: {
                  color: 'orange.500',
                },
                bg: '#ffffff',
              }}
              _pressed={{
                bg: '#ffffff',
              }}
              onPress={() => {
                dispatch(setBack(true));
                navigate(-1);
              }}
            />
          </Box>
        )}
        <Box>
          <Link to="/">
            <Text color="#000000" fontSize="xl" fontWeight="bold">
              DishNote
            </Text>
          </Link>
        </Box>
        <Box width="32px" />
      </HStack>
    </HeaderLayout>
  );
};

export default RestaurantHeader;

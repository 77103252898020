import React, { Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Text, Center } from 'native-base';

import { Router, Routes, Route } from './reactRouter';

import HeaderRoutes from '@app/routes/HeaderRoutes';
import { resetWidth } from '@app/slices/viewSlice';

import HelmetProvider from '@components/Helmet/HelmetProvider';
import ScrollView from '@components/ScrollView';

const Home = React.lazy(() => import('@pages/Home'));
const Restaurant = React.lazy(() => import('@pages/Restaurant'));
const RestaurantList = React.lazy(() => import('@pages/Restaurant/RestaurantList'));
const NotFound = React.lazy(() => import('@pages/NotFound'));

const WebRoute = () => {
  const dispatch = useDispatch();

  // 画面の横幅が変わったときに再レンダリング
  const onLayout = () => {
    dispatch(resetWidth());
  };

  return (
    <Router>
      <HelmetProvider>
        <HeaderRoutes />
        <ScrollView flex={1} backgroundColor="#eeeeee" onLayout={onLayout}>
          <Center>
            <Box width="100%" maxWidth="600px">
              <Suspense fallback={<Text>Loading...</Text>}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/restaurants" element={<RestaurantList />} />
                  <Route path="/restaurants/sort/:sort" element={<RestaurantList />} />
                  <Route path="/restaurants/genres/:genre" element={<RestaurantList />} />
                  <Route path="/restaurants/stations/:station" element={<RestaurantList />} />
                  <Route path="/restaurants/stations/:station/genres/:genre" element={<RestaurantList />} />
                  <Route path="/restaurants/:area1" element={<RestaurantList />} />
                  <Route path="/restaurants/:area1/genres/:genre" element={<RestaurantList />} />
                  <Route path="/restaurants/:area1/:area2" element={<RestaurantList />} />
                  <Route path="/restaurants/:area1/:area2/genres/:genre" element={<RestaurantList />} />
                  <Route path="/restaurants/:area1/:area2/:area3" element={<RestaurantList />} />
                  <Route path="/restaurants/:area1/:area2/:area3/genres/:genre" element={<RestaurantList />} />
                  <Route path="/restaurants/:area1/:area2/:area3/:id" element={<Restaurant />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Suspense>
            </Box>
          </Center>
        </ScrollView>
      </HelmetProvider>
    </Router>
  );
};

export default WebRoute;

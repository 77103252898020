import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from '@config';

export const homeNewPostRestaurantsApi = createApi({
  reducerPath: 'homeNewPostRestaurantsApi',
  baseQuery: fetchBaseQuery({ baseUrl: config.API_BASE_URL }),
  endpoints: (builder) => ({
    getHomeNewPostRestaurants: builder.query({
      query: (limit: number) => `/restaurants?sort=new_post&limit=${limit}`,
    }),
  }),
});

export const { useGetHomeNewPostRestaurantsQuery } = homeNewPostRestaurantsApi;

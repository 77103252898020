import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/*
type StateType = {
  restaurants: any[];
  page: number;
  lastPage: number;
};

const initialState: StateType = {
  restaurants: [],
  page: 1,
  lastPage: 1,
};
*/
type PayloadType = {
  pathname: string;
  restaurants: any[];
  page: number;
  lastPage: number;
};

const initialState: any = {};

export const restaurantsSlice = createSlice({
  name: 'restaurantsSlice',
  initialState: initialState,
  reducers: {
    setRestaurantsState: (state, action: PayloadAction<PayloadType>) => {
      state[action.payload.pathname] = {
        restaurants: action.payload.restaurants,
        page: action.payload.page,
        lastPage: action.payload.lastPage,
      };
      // state.restaurants = action.payload.restaurants;
      // state.page = action.payload.page;
      // state.lastPage = action.payload.lastPage;
    },
  },
});

export const { setRestaurantsState } = restaurantsSlice.actions;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from '@config';

export type restaurantsQueryType = {
  genre: string | undefined;
  area: string | undefined;
  station: string | undefined;
  sort: string | undefined;
  page: number | undefined;
};

export const restaurantsApi = createApi({
  reducerPath: 'restaurantsApi',
  baseQuery: fetchBaseQuery({ baseUrl: config.API_BASE_URL }),
  endpoints: (builder) => ({
    getRestaurants: builder.query({
      query: (query: restaurantsQueryType) => {
        const genre = query.genre ?? '';
        const area = query.area ?? '';
        const station = query.station ?? '';
        const sort = query.sort ?? '';
        const page = query.page ?? 1;
        return `/restaurants?genre=${genre}&area=${area}&station=${station}&sort=${sort}&page=${page}`;
      },
    }),
  }),
});

export const { useGetRestaurantsQuery } = restaurantsApi;
